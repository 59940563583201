import React, { useState, forwardRef, useImperativeHandle } from "react";
import Timer from "./Timer";
import ControlButtons from "./ControlButtons";

const StopWatch = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    childFunction1() {
      toggleStartStop();
    },
  }));
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);

  React.useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const toggleStartStop = () => {
    setIsActive(!isActive);
    setIsPaused(!isPaused);
  };

  const handlePauseResume = () => {
    setIsPaused(!isPaused);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
  };

  return (
    <div className="stop-watch">
      <Timer time={time} />
      <ControlButtons
        active={isActive}
        isPaused={isPaused}
        handleStart={handleStart}
        handlePauseResume={handlePauseResume}
        handleReset={handleReset}
      />
    </div>
  );
});

export default StopWatch;
