import React from "react";
import AppColumn from "../components/AppColumn";
import SurveyComponent from "../components/Dashboard";

const SurveyPage = () => {
  return (
    <>
      <AppColumn>
        <SurveyComponent />
      </AppColumn>
    </>
  );
};

export default SurveyPage;
