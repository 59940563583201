import React from "react";
import MapComponent from "../Map";

const SurveyComponent = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Survey Placeholder</h1>
      <MapComponent />
    </div>
  );
};

export default SurveyComponent;
