import React from "react";
import CreateSurveyComponent from "../components/CreateSurvey";

const CreateSurveyPage = () => {
  return (
    <>
      <CreateSurveyComponent />
    </>
  );
};

export default CreateSurveyPage;
