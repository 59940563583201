import React from "react";
import ActiveSurvey from "components/ActiveSurvey/activeSurvey";

export default function ActiveSurveyButterflies() {
  return(
    <div>
      <ActiveSurvey />
    </div>
  )
}
