import React from "react";
import AppColumn from "../components/AppColumn";

const Help = () => {
  return (
    <>
      <AppColumn>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h4>Help Placeholder</h4>
        </div>
      </AppColumn>
    </>
  );
};

export default Help;
