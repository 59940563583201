import React from "react";
import ListDashboard from "../components/ListManager/ListDashboard";

const LoadedListsPage = () => {
  return (
    <>
        <ListDashboard />
    </>
  );
};

export default LoadedListsPage;