import React from "react";
import LoginRegister from "../components/LoginRegister";

const LoginRegPage = () => {
  return (
    <>
      <LoginRegister />
    </>
  );
};

export default LoginRegPage;
